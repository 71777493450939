@import url('https://fonts.googleapis.com/css2?family=Poppins:display=swap');


 :root{
    
   --main-color: #0164AE;

 }


::placeholder {
  color: var(--main-color);
  opacity: 1; /* Firefox */
}

fieldset{
    border-radius: 25px;
    border-color: #0164AE;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
  }


.loginOverlay{
    position: absolute;
width: 100%;
height: 100%;
left: 0px;
top: 0px;

background: rgba(1, 100, 174, 0.78);
}


.vector {
    position: absolute;
    left: 52.33%;
    right: 8.33%;
    top: 9.33%;
    bottom: 5.33%;
  }
  
  .vectorActive{
    position: absolute;
    left:12.33%;
    right:10.33%;
    top:10%;
    bottom: 15%;
  }
  
  .vector1 {
    position: absolute;
    left: 8.33%;
    right: 8.33%;
    top: 8.33%;
    bottom: 8.33%;
  }
  
  .vectorActive1{
    position: absolute;
    left:3.33%;
    right:10.33%;
    top:10%;
    bottom: 15%;
  }
  
  .vector21 {
    position: absolute;
    left: 8.33%;
    right: 8.33%;
    top: 16.67%;
    bottom: 16.67%;
  }
  .vectorActive21{
    position: absolute;
    left: 3.33%;
    right: 8.33%;
    top: 16.67%;
    bottom: 16.67%;
  }
  
  
  .vector22 {
    position: absolute;
    left: 8.33%;
    right: 22.92%;
    top: 34.58%;
    bottom: 25.25%;
  }
  .vectorActive22{
    
    position: absolute;
    left: 3.33%;
    right: 22.92%;
    top: 34.58%;
    bottom: 25.25%;
  }
  .vector23 {
    position: absolute;
    left: 6.33%;
    right: 64.58%;
    top: 52.5%;
    bottom: 13.42%;
  }
  
  .vectorActive23{
    position: absolute;
    left: 3%;
    right: 64.58%;
    top: 54.5%;
    bottom: 13.42%;
  }
  
  .vector24 {
    position: absolute;
    left: 27.34%;
    right: 64.58%;
    top: 70.5%;
    bottom: 8.33%;
  }
  .vectorActive24{
    position: absolute;
    left: 7.34%;
    right: 64.58%;
    top: 72.5%;
    bottom: 8.33%;
  }
  
  .vector31 {
    position: absolute;
    left: 11.33%;
    right: 8.33%;
    top: 11.33%;
    bottom: 8.33%;
  }
  .vector32 {
    position: absolute;
    left: 27.08%;
    right: 27.08%;
    top: 27.08%;
    bottom: 27.08%;
  }
  
  .vectorActive31{
    position: absolute;
    left: 3.33%;
    right: 8.33%;
    top: 11.33%;
    bottom: 8.33%;
  }
  .vectorActive32{
    position: absolute;
    left: 6.08%;
    right: 27.08%;
    top: 27.08%;
    bottom: 27.08%;
  }
  
.logout1{
    position: absolute;
     top:17px;
     left:3px; 
   
}  

.logout2{
    position: absolute;
    left:21px;  
}
.textFieldWrapper *{
  border-color: #0164AE !important;
  border-radius: 20px !important;
  color: black;
}

.css-1ymkqpa{
  border-color: #0164AE !important;
}
*::placeholder {
    color: #0164AE !important;
}
.css-1ymkqpa *{
  color:#0164AE !important;
}
.textFieldWrapper label{
  color : #0164AE !important;
}
.textFieldWrapper textarea{
  padding: 6px;
}

.LoginInput * {
  border-color: #0164AE !important;
  border-radius: 20px !important;
  color:black !important;
}
.backButton{
  position: fixed;
  right: 2%;
  bottom: 2%;
  
}


.DatePicker{
  font-size: 17px;
    line-height: 35px;
    color:grey;
    border-radius: 20px;
    height: 47px;
    padding: 0 10px;
    border: 1px solid rgba(16, 99, 173, 0.90);
}
.no-spinner {
  -moz-appearance: textfield; /* Hide the spinner arrows in Firefox */
}

.m-0{
  margin: 0 !important;
}
.css-mfa1xm {
  font-size: 32px;
  font-weight: 600 !important;
  line-height: 67px;
  margin-left: 25px;
  color: var(--main-color);
  display: flex;
}

.small-heading {
  font-size: 21px;
  margin-left: 0.5rem;
}