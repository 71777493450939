.css-1lambp5 .MuiOutlinedInput-root {
    padding: 2px !important;
    border-color: red !important;
}

.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 2px !important;
    border-color: red !important;
}

.css-l3ln04 .MuiOutlinedInput-root {
    padding: 2px !important;
    border-color: red !important;
}

.searchBar * {
    border: #C4C4C4 !important;
    border-color: red !important;
}

.textAlignRight input {
    text-align: right !important;
}
/* styles.css */
.no-spinner {
    -moz-appearance: textfield; /* Hide the spinner arrows in Firefox */
  }
  
